<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <div class="my-2">
          <h3>{{ t('personalisedBase') }}</h3>
        </div>
      </template>
    </Toolbar>
    <div class="col-12">
      <div class="card">
        <DataTable :value="assignDocuments"
                   :paginator="true" class="p-datatable-gridlines table-filter-texte"
                     :rows="10" :totalRecords="nbElmentsAssignDocuments" dataKey="id" :rowHover="true"
                   v-model:filters="filtersAssignDocument" filterDisplay="menu"
                   responsiveLayout="scroll"
                   :globalFilterFields="['titleDescription','dated_at','last_updated_at','theme','domain_h','sub_domain_h', 'applicability']"
                   :loading="loadingAssignDocuments"
                   @filter="filterDataTable($event)"
                   @update:filters="handleFilterApplied">
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
          <template #loading>
            {{ t('LoadingDataPleaseWait') }}
          </template>
          <Column header="" class="td_flag">
            <template #body="slotProps">
              <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.document?.live_status?.color=='black'"/>
              <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.document?.live_status?.color=='red'"/>
              <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.document?.live_status?.color=='yellow'"/>
              <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="20"
                   v-if="slotProps?.data?.document?.live_status?.color=='green'"/>
            </template>
          </Column>
          <Column filterField="titleDescription" :header="t('text')" style="width:8rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <div class="tooltip"
                   v-tooltip.right="'<p><strong> '+  t('text') +' : </strong>'+data.document.title+'</p><p><strong>Description : </strong>'+
                       replaceAllColorsWithWhite(data.document.description)+'</p><p><strong>'+t('fieldApplication') +' : </strong>'+data.document.application_fields+'</p>'">
                <p>{{ data.document.title }}</p>
                <p v-html="data.document.description"  class="text-light"></p>
              </div>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" :placeholder="t('search')"/>
            </template>
          </Column>
          <Column :header="t('dateOfText')" style="min-width:8rem" filterField="dated_at" dataType="date"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              {{ formatDate(data.document.dated_at) }}
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" selectionMode="range"/>
            </template>
          </Column>
          <Column :header="t('updatedAt')" style="min-width:70px" filterField="last_updated_at"
                  :showFilterMatchModes="false">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text">{{ formatDate(data.document.last_updated_at) }}</span>
            </template>
            <template #filter="{filterModel}">
              <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" selectionMode="range"/>
            </template>
          </Column>
          <Column :header="t('theme')" filterField="theme" style="min-width:7rem" :showFilterMatchModes="false">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.document.theme"
                      class="image-text">{{ data.document.theme.name }}</span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="categoriesThemeDocFilter" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column :header="t('domain')" filterField="domain_h" :showFilterMatchModes="false"
                  :filterMenuStyle="{'width':'14rem'}" style="min-width:7rem">
            <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.document.domain"
                      class="image-text">{{ data.document.domain.name }}</span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="categoriesDomainfilter" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column :header="t('subDomain')" filterField="sub_domain_h" style="min-width:7rem"
                  :showFilterMatchModes="false">
            <template #body="{data}">
              <span v-if="data.document.sub_domain">{{ data.document.sub_domain.name }}</span>
            </template>
            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="categoriesSubDomainFilter" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column :header="t('applicability')" style="min-width:7rem" filterField="applicability" :showFilterMatchModes="false">
            <template #body="{data}">
<!--              <span v-if="data.is_applicable"> Avec les exigences </span>-->
<!--              <span v-else> Pour information </span>-->

              <span>
                {{ data.applicability}}
              </span>
            </template>

            <template #filter="{filterModel}">
              <Dropdown v-model="filterModel.value" :options="applicabilities" placeholder=" Any "
                        class="p-column-filter" :showClear="true">
                <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                  <span v-else>{{ t('select') }}</span>
                </template>
                <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                </template>
              </Dropdown>
            </template>

          </Column>
          <Column :header="t('supportingDocuments')" style="min-width:8rem">
            <template #body="slotProps">
              <Button icon="pi pi-file-pdf" class="btn_file_pdf button-table mr-2"
                      @click="attachFile(slotProps.data.document?.summary_file?.id, slotProps.data.document?.summary_file?.name)"
                      v-tooltip.top="{value:t('synthesis'), class: 'editBtn'}"
                      :disabled="!slotProps.data.document?.summary_file"/>
              <Button icon="pi pi-file" class="btn_file button-table mr-2"
                      @click="attachFile(slotProps.data.document.illustrations_file?.id, slotProps.data.document?.summary_file?.name)"
                      v-tooltip.top="{value:'Document', class: 'editBtn'}"
                      :disabled="!slotProps.data.document?.illustrations_file"/>
              <a :href="slotProps.data.document.url" target="_blank">
                <Button icon="pi pi-link" class="btn_link button-table mr-2"
                        v-tooltip.top="{value:t('link'), class: 'editBtn'}"/>
              </a>
              <Button icon="pi pi-fw pi-comment" class="btn_comment button-table mr-2"
                      @click="editDocument(slotProps.data)" v-tooltip.top="{value: 'Hotline', class: 'editBtn'}"
                      disabled/>
              <Button icon="pi pi-check-square" class="button-table mr-2" @click="editDocument(slotProps.data)"
                      v-tooltip.top="{value:t('textEvaluation'), class: 'editBtn'}" disabled/>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
  <Toast/>
</template>
<script>

import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {FilterMatchMode} from "primevue/api";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import {useRoute} from "vue-router";
import {computed} from "vue";
import {saveAs} from 'file-saver';
import moment from "moment-timezone";

export default {
  name: "Peronnalised-base",

  data() {
    return {
      categoriesTheme: [],
      loadingAssignDocuments: false,
      assignDocuments: [],
      nbElmentsAssignDocuments: 0,
      nbPageAssignDocuments: 1,
      nbPageDocuments: 1,
      filters1: null,
      filtersAssignDocument: null,
      id_site: '',
      categoryValueTheme: null,
      categoriesDomain: [],
      categoriesDomainfilter: [],
      categoryValueDomain: null,
      categoriesSubDomain: [],
      categoriesSubDomainFilter: [],
      categoryValueSubDomain: null,
      categoriesThemeDoc: [],
      categoriesThemeDocFilter: [],
      categoriesThemeDocFilterConst: [],
      submitted: false,
      applicabilities: ['Pour information','Avec exigences'],
      listThemeAffect: [],
      listDomainAffect: [],
      listSubDomainAffect: [],
      filterThemeValue: '',
      filterDomainValue: '',
      categoriesDomainfilterConst: [],
      categoriesSubDomainFilterConst: [],

    }
  },
  setup() {
    const idSite = computed(() => {
      return store.getters.currentSite.id
    });
    const primevue = usePrimeVue();
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    return {idSite, store, route, toast, primevue, t: primevue.config.StoreTranslation.t}
  },
  watch: {
    idSite() {
      this.id_site = this.idSite
    }
  },
  mounted() {
    this.getDocumentsBySite(1, this.idSite)
  },
  beforeMount() {
    this.getCategoriesByThemeDoc()
  },
  created() {
    this.initFilters1();
  },
  methods: {
    initFilters1() {
      this.filtersAssignDocument = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'dated_at': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain_h': {value: null, matchMode: FilterMatchMode.EQUALS},
        'sub_domain_h': {value: null, matchMode: FilterMatchMode.EQUALS},
        'applicability': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []
      this.store.dispatch(Actions.GET_CATEGORIES, {type: 'THEME', 'customHeader': false,}).then(data => {
        data.forEach(function (element) {
          if (element.type === 'THEME') {
            tabCategoriesTheme.push(element)
          }
        });
        this.categoriesTheme = tabCategoriesTheme;
      })
    },
    getDocumentsBySite(page = 1, id_site) {
        this.categoriesThemeDocFilter = []
      console.log(page)
      this.loadingAssignDocuments = true
      let idTheme
      let idDomain
      let idSubDomain
      if (this.categoryValueTheme) {
        idTheme = this.categoryValueTheme.id
      }
      if (this.categoryValueDomain) {
        idDomain = this.categoryValueDomain.id
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      }
      this.store.dispatch(Actions.AFFECTATION_DOCUMENTS, {
        'idSite': id_site,
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'dated_at': this.dated_at_format,
        'last_updated_at': this.last_updated_at_format,
        'customHeader': false,
      }).then(data => {
        this.assignDocuments = data
        this.assignDocuments.forEach(element => {
          element.title = element.document.title
          element['titleDescription'] = element['title'] + '' + element.document?.description + '' + element.document?.application_fields
          if (element.document.theme) {
              let theme_object = element.document.theme
                 element.theme = theme_object.name
              if (!this.listThemeAffect.find(item => item.id === theme_object.id)){
                  this.listThemeAffect.push(theme_object)
                  this.categoriesThemeDocFilter.push(theme_object.name)
              }
          }
          if (element.document.domain) {
             let domain_object = element.document.domain
            let newObjectDomain = { ...domain_object}
            element.domain = domain_object.name
            newObjectDomain.name += ' ( ' + newObjectDomain.parent?.name + ' ) '
            element['domain_h'] = newObjectDomain.name
              if (!this.listDomainAffect.find(item => item.id === domain_object.id)){
                  this.listDomainAffect.push(domain_object)

                    this.categoriesDomainfilter.push(newObjectDomain.name)
              }
          }
          if (element.document.sub_domain) {
              let sub_domain_object = element.document.sub_domain
              let newObjectSubDomain = { ...sub_domain_object}
             element.subDomain = sub_domain_object.name
            newObjectSubDomain.name += ' ( ' + newObjectSubDomain.parent?.name + ' ) '
            element['sub_domain_h'] = newObjectSubDomain.name
              if (!this.listSubDomainAffect.find(item => item.id === sub_domain_object.id)){
                  this.listSubDomainAffect.push(sub_domain_object)

                  this.categoriesSubDomainFilter.push(newObjectSubDomain.name)
              }
          }
          element.dated_at = new Date(element.document.dated_at)
          element.last_updated_at = new Date(element.document.last_updated_at)

          if(element.is_applicable){
            element.applicability = 'Avec exigences'
          }
          else {
            element.applicability = 'Pour information'
          }
        })


        this.categoriesThemeDocFilter.sort((a, b) => a.localeCompare(b));
        this.categoriesThemeDocFilterConst = this.categoriesThemeDocFilter
        this.categoriesDomainfilter.sort((a, b) => a.localeCompare(b));
        this.categoriesDomainfilterConst = this.categoriesDomainfilter
        this.categoriesSubDomainFilter.sort((a, b) => a.localeCompare(b));
        this.categoriesSubDomainFilterConst = this.categoriesSubDomainFilter
        this.nbElmentsAssignDocuments = this.assignDocuments.length
        this.loadingAssignDocuments = false
      })
    },
    formatDate(value) {
      if(value){
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
      else {
        return '';
      }
    },
    getCategoriesByThemeDoc() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []
      this.store.dispatch(Actions.GET_CATEGORIES_TREE, {'customHeader': false,}).then(data => {
        var formatSelectTree = (obj, indexCh = null) => {
          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories, indexF)

              if (element.type == 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element.type == 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
            } else {
              if (element.type == 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }
            Object.assign(element, {"children": element.categories})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})
            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })
          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)

        this.valuesSubDomain = tabCategoriesSubDomain;
      })
    },
    changeTheme() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

      if(this.filterThemeValue){
        let filter_theme_obj = this.listThemeAffect.find(item => item.name == this.filterThemeValue)
        let filter_theme_id = filter_theme_obj?.id
        if (filter_theme_id){
          tabCategoriesDomain = this.listDomainAffect.filter(item => item.parent?.id == filter_theme_id).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')
          tabCategoriesSubDomain = this.listSubDomainAffect.filter(item => item.parent?.parent?.id == filter_theme_id).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')
        }

        this.categoriesDomainfilter = tabCategoriesDomain
        this.categoriesSubDomainFilter = tabCategoriesSubDomain
        this.categoriesDomainfilter.sort((a, b) => a.localeCompare(b));
        this.categoriesSubDomainFilter.sort((a, b) => a.localeCompare(b));

      }

    },
    changeDomain() {
      let tabCategoriesSubDomain = []
      if(this.filterDomainValue){
        const match_theme = this.filterDomainValue.match(/\(\s+([^\s)]+)\s+\)/);
        let theme = match_theme?  match_theme[1].trim() : null;

        const match_domain = this.filterDomainValue.match(/^(.*?)(?=\(\s+[^\s)]+\s+\))/);
        let domain = match_domain?  match_domain[1].trim() : match_domain;

        if (theme && this.filterThemeValue){
          let filter_theme_obj = this.listThemeAffect.find(item => item.name == theme)
          let filter_theme_id = filter_theme_obj?.id

          let filter_domain_obj = this.listDomainAffect.filter(item => item.parent?.id == filter_theme_id && item.name == domain)
          if (filter_domain_obj.length > 0 ){
            tabCategoriesSubDomain = this.listSubDomainAffect.filter(item => item.parent?.id == filter_domain_obj[0]?.id).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')

            this.categoriesSubDomainFilter = tabCategoriesSubDomain
            this.categoriesSubDomainFilter.sort((a, b) => a.localeCompare(b));
          }

        }
        else if (!this.filterThemeValue){
          this.categoriesDomainfilter = this.categoriesDomainfilterConst
        }

      }

    },

    changeSubDomain(){
      this.categoriesSubDomainFilter = this.categoriesSubDomainFilterConst
    },

    attachFile(idMedia, name) {
        this.store.dispatch(Actions.ATTACH_FILES, {idMedia: idMedia, 'customHeader': true}).then((data, filename) => {
          const url = window.URL.createObjectURL(data, filename);
          saveAs(url, name)
        })
    },
    filterDataTable(event){
      this.filterThemeValue = event?.filters?.theme?.value
      this.filterDomainValue = event?.filters?.domain_h?.value
      if (event?.filters?.theme?.value){
        this.changeTheme()
      }
      if (event?.filters?.domain_h?.value)
        this.changeDomain()

      if (!event?.filters?.theme?.value && !event?.filters?.domain_h?.value){
        this.changeSubDomain()
      }



    },
    handleFilterApplied(event){
      if (event.dated_at?.value) {
        if (!event.dated_at?.value[1]) {
          event.dated_at.value = [
            event.dated_at?.value[0],
            this.addEndOfDayTime(moment(event.dated_at?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
      if (event.last_updated_at?.value) {
        if (!event.last_updated_at?.value[1]) {
          event.last_updated_at.value = [
            event.last_updated_at?.value[0],
            this.addEndOfDayTime(moment(event.last_updated_at?.value[0]).format('yy-MM-DD'))
          ];

        }
      }
    },
    addEndOfDayTime(dateString) {
      const endDate = new Date(dateString);
      // Réglage de l'heure à 23:59:59
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      return endDate;

    },
    replaceAllColorsWithWhite(html) {
      if (!html) return '';
      return html.replace(/color: rgb\(\d{1,3}, \d{1,3}, \d{1,3}\);/g, 'color: white;');
    },
  }
}
</script>
<style scoped lang="scss">
@import '../../../assets/demo/styles/badges';

::v-deep(.p-progressbar) {
  height: .5rem;
  //background-color: green;

  //.p-progressbar-value {
  //  background-color: green;
  //}
}

//.p-datatable .p-datatable-tbody > tr.no-expander > td.p-row-toggler.p-link  {
//  display: none;
//}
//.p-link{
//  display: none;
//}

</style>

